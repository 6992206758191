@import "../../variables.styles.scss";

.tips {
  background-color: black;

  &__text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 8px 0;
    margin: 0;
    color: $main-color-white;
    font-size: 0.8em;
    font-weight: bold;
    text-align: center;
  }
}
