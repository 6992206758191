@import "../../variables.styles.scss";

.menu {
  padding: 30px 0;
  min-width: $min-width;
  min-height: 50vh;
  background-color: $main-color-blue;
  background-image: url("../../assets/background.png");

  &__title {
    margin: 0;
    font-family: "Merriweather";
    color: $main-color-green;
    text-transform: uppercase;
    text-shadow: black 0px 0px 3px;
    font-size: 1.6em;
  }

  &__search {
    margin-top: 15px;
    display: flex;
  }

  &__tips {
    &-title {
      padding: 5px 5px 5px 0px;
      margin: 0;
      color: $main-color-green;
      font-size: 0.9em;
      text-shadow: black 0px 0px 3px;
    }

    &-sub-categories {
      display: flex;
      gap: 5px;
      flex-wrap: wrap;
    }

    &-sub-category {
      transition: all 0.5s;

      &:hover {
        cursor: pointer;
        transform: scale(1.1);
      }
    }
  }
}
