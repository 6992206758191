@import "../../variables.styles.scss";

.history {
  min-width: $min-width;
  background-color: #cbd9667a;
  overflow: hidden;

  &__title {
    padding: 20px;
    margin: 0;
    background-color: $main-color-green;
    font-family: "Merriweather";
    color: $main-color-pink;
    text-align: center;
    text-transform: uppercase;
  }

  &__wrapper {
    position: relative;
    padding: 20px 0;

    @media (min-width: $breakpoint-sm) {
      padding: 60px 0;
    }
  }

  p {
    margin: 0 0 10px;

    &:last-child {
      margin: 0;
    }
  }

  @keyframes history-image-fade-in {
    from {
      opacity: 0;
      margin-left: -500px;
    }
    to {
      opacity: 1;
    }
  }

  &__image {
    position: absolute;
    top: 35px;
    left: 15px;
    width: 150px;
    border-radius: 10px;
    z-index: 1;
    animation: 2s ease-out history-image-fade-in;

    @media (min-width: 500px) {
      width: 200px;
    }

    @media (min-width: $breakpoint-sm) {
      width: 300px;
      left: -25px;
    }

    @media (min-width: $breakpoint-md) {
      width: 350px;
    }

    @media (min-width: $breakpoint-lg) {
      width: 400px;
    }

    @media (min-width: $breakpoint-xl) {
      width: 450px;
    }

    &-placeholder {
      position: relative;
      float: left;
      width: 155px;
      height: 150px;
      border-radius: 10px;

      @media (min-width: 500px) {
        width: 205px;
        height: 200px;
      }

      @media (min-width: $breakpoint-sm) {
        width: 260px;
        height: 255px;
      }

      @media (min-width: $breakpoint-md) {
        width: 310px;
        height: 305px;
      }

      @media (min-width: $breakpoint-lg) {
        width: 365px;
        height: 355px;
      }

      @media (min-width: $breakpoint-xl) {
        width: 415px;
        height: 405px;
      }
    }
  }

  @keyframes history-text-fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &__text {
    padding: 15px;
    background-color: $main-color-green;
    border-radius: 10px;
    opacity: 0.87;
    animation: 3s linear history-text-fade-in;

    p {
      opacity: 1;
    }

    @media (min-width: $breakpoint-sm) {
      padding: 20px;
      position: relative;
      border-radius: 10px;
    }
  }
}
