@import "../../variables.styles.scss";

.special {
  min-width: $min-width;
  background-color: #cbd9667a;

  &__title {
    padding: 20px;
    margin: 0;
    background-color: $main-color-green;
    font-family: "Merriweather";
    color: $main-color-pink;
    text-align: center;
    text-transform: uppercase;
  }

  &__products {
    padding: 20px 0;

    .product {
      margin: auto;
    }
  }

  &__container {
    padding: 0 0 20px 0;
    text-align: end;
  }

  &__link {
    text-align: end;
    text-decoration: none;
    padding: 0 0 20px 0;
    font-family: "Merriweather";
    font-size: 1.25em;
    font-weight: bold;
    color: $main-color-pink;

    &:hover {
      color: #d9325191;
    }
  }
}
