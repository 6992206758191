@import "../../variables.styles.scss";

.product {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: border 0.5s;
  background-color: $main-color-blue;
  border: 3px solid $main-color-green;
  border-radius: 10px;
  box-shadow: 4px 4px 4px #06060636;
  color: #313145;
  width: 220px;

  @media (min-width: $breakpoint-md) {
    width: 250px;
  }

  &:hover {
    border: 3px solid $main-color-pink;

    .product-body-footer__more {
      text-shadow: #d93250 0px 0px 10px;
    }
  }

  &--cursor {
    cursor: pointer;
  }

  &-header {
    &__image {
      width: 100%;
      min-height: 150px;
      background-image: url("../../assets/missing-image.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-top-right-radius: 7px;
      border-top-left-radius: 7px;
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 16px;

    &__name {
      margin-bottom: 5px;
      font-size: 1.3em;
      font-weight: bold;
      transform: none;
    }

    &-strength {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.8em;

      &__title {
        font-weight: bold;
      }

      &__image {
        height: 19px;
      }
    }

    &-flavors {
      padding: 5px 0 0 0;
      font-size: 0.9em;

      &__title {
        font-weight: bold;
      }
    }

    &-options {
      padding: 5px 0 5px 0;
      font-size: 0.9em;

      &__title {
        font-weight: bold;
      }
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      &__price {
        font-size: 0.85em;
        padding: 5px 0 0 0;
        color: #000000;
      }

      &__more {
        color: $main-color-green;
        font-weight: bold;
        text-shadow: black 0px 0px 3px;
        transition: text-shadow 0.5s;
        font-size: 0.9em;
      }
    }

    &__title {
      display: block;
      font-size: 0.9em;
      font-weight: bold;
    }

    &__description {
      padding: 10px 0 0 0;
    }

    &__ingredients {
      padding: 10px 0 0 0;
    }
  }
}
