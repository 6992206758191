.carosel {
  &__image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 70vh;
    width: 100%;
  }

  &__paragraph {
    position: absolute;
    bottom: 20px;
    left: 10%;
    width: 40%;
    min-width: 230px;
    height: 150px;
    padding: 15px;
    border-radius: 15px;
    background-color: rgb(0 0 0 / 65%);
    color: white;
  }
}
