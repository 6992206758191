@import "../../variables.styles.scss";

.input {
  position: relative;

  &-field {
    padding: 0;

    > input {
      width: 100%;
      padding-left: 1.2em;
      padding-top: 1.2em;
      font-size: 1.0625rem;
      line-height: 147.6%;
      background-color: $main-color-white;

      &:focus {
        outline: none;
      }
    }

    > .input-label {
      position: absolute;
      top: 15px;
      left: 24px;
      line-height: 147.6%;
      transition: top 0.2s;
    }

    > input:focus + .input-label,
    > input:valid + .input-label {
      top: 4px;
      font-size: 0.8em;
    }
  }
}
