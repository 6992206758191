@import "../../variables.styles.scss";

.category {
  &-title {
    padding: 30px 0 10px 0;
    margin: 0;
    font-family: "Merriweather";
    color: $main-color-green;
    text-shadow: black 0px 0px 3px;
    font-size: 1.4em;
  }

  &-line {
    background-color: $main-color-green;
    margin: 0 0 18px 0;
    height: 5px;
    width: 100%;
    box-shadow: black 0px 0px 2px;
  }
}

.loading-logo {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 10px 0;
  background-image: url("../../assets/components/logo/coffee-shaped-heart-pink.svg");
  background-repeat: no-repeat;
  transform-origin: 40px 40px;
  animation: loading-logo 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

@keyframes loading-logo {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}
