@import "../../variables.styles.scss";

.media {
  min-width: $min-width;
  background-color: #73b1bf7a;

  a {
    display: contents;
    text-decoration: none;
    color: $main-color-text;

    &:visited {
      color: inherit;
    }
  }

  &__title {
    padding: 20px;
    margin: 0;
    background-color: $main-color-blue;
    font-family: "Merriweather";
    color: $main-color-green;
    text-align: center;
    text-transform: uppercase;
  }

  &__posts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px 15px;
    padding: 20px;
  }

  @keyframes media-posts-fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.87;
    }
  }

  &__post {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 260px;
    max-width: 260px;
    background-color: #73b1bfe3;
    border: 3px solid $main-color-green;
    border-radius: 10px;
    box-shadow: 4px 4px 4px #06060636;
    transition: border 0.5s;
    animation: 1s linear media-posts-fade-in;

    &:hover {
      border: 3px solid $main-color-pink;
      cursor: pointer;

      & .media__post-body-title {
        text-decoration: underline;
      }
    }

    &-header {
      height: fit-content;

      &-source {
        position: absolute;
        top: 5px;
        left: 1%;
        padding: 10px;
        margin: 0;
        width: 98%;
        border-radius: 5px;
        background-color: $main-color-blue;
        font-family: "Merriweather";
        text-align: center;
      }

      &-image {
        border-radius: 7px 7px 0 0;
      }
    }

    &-body {
      padding: 0 10px 10px 10px;

      &-title {
        margin: 0;
      }

      &-date {
        font-size: small;
      }

      &-sumary {
        margin: 0;

        &::after {
          content: "...";
        }
      }
    }
  }
}
