@import "../../variables.styles.scss";

.static-whatsapp {
  position: fixed;
  bottom: 30px;
  left: 30px;
  padding: 12px;
  background-color: #075E54;
  border-radius: 50%;
  box-shadow: -3px 2px 4px #0000008c;
  transition: background-color 0.5s;
  z-index: 1002;

  @media (min-width: $breakpoint-sm) {
    box-shadow: 3px 2px 4px #0000008c;
    left: auto;
    right: 30px;
  }

  &:hover {
    cursor: pointer;
    background-color: #05423b;
  }
}