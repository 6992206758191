@import "../../variables.styles.scss";

.product-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  align-items: baseline;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  justify-items: center;

  @media (min-width: 515px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: $breakpoint-lg) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: $breakpoint-xl) {
    grid-template-columns: repeat(4, 1fr);
  }
}
