$main-color-pink: #d93250;
$main-color-blue: #73b1bf;
$main-color-green: #cbd966;
$main-color-text: #2c2c2c;
$main-color-white: #e9f5eb;

$min-width: 300px;

$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
