@import "../../variables.styles.scss";

.logo {
  float: left;
  width: 140px;
  height: auto;
  padding: 20px 0;
}

.navigation {
  background-color: $main-color-pink;
  width: 100%;
  box-shadow: 0px 4px 5px #06060636;
  z-index: 1002;

  &__content {
    position: relative;

    @media (min-width: $breakpoint-lg) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__menu-button {
    display: none;

    &:checked {
      & ~ .navigation__list {
        height: 100px;

        @media (min-width: $breakpoint-sm) {
          height: 125px;
        }
      }

      & ~ label {
        > span {
          background-color: transparent;
          transform: rotate(90deg);

          &:before {
            margin-top: 1px;
            transform: translate(-50%, -50%) rotate(45deg);
          }

          &:after {
            margin-top: 1px;
            transform: translate(-50%, -50%) rotate(-45deg);
          }
        }
      }
    }

    &-label {
      position: relative;
      float: right;
      top: 23px;
      display: block;
      height: 40px;
      width: 40px;
      border: 3px solid white;
      border-radius: 5px;

      &:hover {
        cursor: pointer;
      }

      @media (min-width: $breakpoint-lg) {
        display: none;
      }

      > span {
        margin: 15px 0 0 3px;
        position: absolute;
        width: 80%;
        height: 3px;
        background-color: $main-color-white;
        pointer-events: auto;
        transition-duration: 0.35s;
        transition-delay: 0.35s;

        &:before,
        &:after {
          position: absolute;
          display: block;
          width: 100%;
          height: 3px;
          left: 50%;
          background-color: $main-color-white;
          content: "";
          transition: transform 0.35s;
          transform-origin: 50% 50%;
        }

        &:before {
          transform: translate(-50%, -7px);
        }

        &:after {
          transform: translate(-50%, 7px);
        }
      }
    }
  }

  &__list {
    height: 0;
    width: 100%;
    padding: 0;
    margin: 0;
    transition: height 246ms 0.5s ease;
    list-style: none;
    overflow: hidden;

    @media (min-width: $breakpoint-lg) {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 30px;
      height: 79.78px !important;
    }

    &-item {
      width: 100%;
      padding: 6px 0;
      font-family: "Merriweather";
      text-decoration: none;
      display: inline-block;
      font-weight: bold;
      color: $main-color-white;

      &:hover {
        color: $main-color-green;
        background-color: rgba(255 255 255 / 12%);
      }

      &--atual {
        color: $main-color-green;
        cursor: default;

        &:hover {
          background-color: inherit;
        }
      }

      @media (min-width: $breakpoint-md) {
        border-bottom: none;

        &:hover {
          background-color: inherit;
        }
      }
    }
  }
}
