@import "../../variables.styles.scss";

.home {
  &__content {
    background-color: $main-color-white;
    background-image: url("../../assets/routes/home/background.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
