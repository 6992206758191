@import "../../variables.styles.scss";

.modal {
  &__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1010;
    background: rgba(0, 0, 0, 0.6);
  }

  &--close {
    display: none;
  }

  &__content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    max-width: 80%;
    z-index: 1011;
    border: 3px solid $main-color-green;
    border-radius: 10px;
    background-color: $main-color-blue;
    box-shadow: 4px 4px 4px #06060636;
    color: #313145;
    overflow: hidden;

    &-close-button {
      position: absolute;
      right: 25px;
      top: 25px;
      display: block;
      height: 40px;
      width: 40px;
      background-color: $main-color-pink;
      border: 3px solid white;
      border-radius: 5px;

      &:hover {
        cursor: pointer;
      }

      > span {
        margin: 17px 0 0 4px;
        position: absolute;
        width: 80%;
        height: 0;
        background-color: #fff;
        pointer-events: auto;
        transition-duration: 0.35s;
        transition-delay: 0.35s;
        transform: rotate(90deg);

        &:before,
        &:after {
          position: absolute;
          display: block;
          width: 100%;
          height: 3px;
          left: 50%;
          background-color: #fff;
          content: "";
          transition: transform 0.35s;
          transform-origin: 50% 50%;
        }

        &:before {
          margin-top: 1px;
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &:after {
          margin-top: 1px;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }

    &-header {
      &-image {
        width: 100%;
        min-height: 190px;
        background-image: url("../../assets/missing-image.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-top-right-radius: inherit;
        border-top-left-radius: inherit;

        @media (min-width: $breakpoint-lg) {
          min-height: 260px;
        }
      }
    }

    &-body {
      padding: 20px;
      max-height: 200px;
      overflow: auto;

      @media (min-width: $breakpoint-md) {
        max-height: 400px;
      }

      &-name {
        margin-bottom: 5px;
        font-size: 1.3em;
        font-weight: bold;
        transform: none;
        padding: 0 20px 0 0;
      }

      &-strength {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 0.8em;

        &-title {
          font-weight: bold;
        }

        &-image {
          height: 19px;
        }
      }

      &-price {
        font-size: 0.9em;
        color: #40464b;
      }

      &-description {
        padding: 0 20px 0 0;

        &-title {
          display: block;
          font-size: 0.9em;
          font-weight: bold;
        }
      }

      &-ingredients {
        padding: 10px 20px 0 0;

        &-title {
          display: block;
          font-size: 0.9em;
          font-weight: bold;
        }
      }

      &-sub-categories {
        padding: 15px 20px 0 0;
      }

      &-flavors {
        padding: 5px 20px 0 0;

        &-title {
          display: block;
          font-size: 0.9em;
          font-weight: bold;
        }

        ul {
          margin: -3px 0 0 0;
          padding: 0 0 0 20px;
        }
      }

      &-options {
        padding: 5px 20px 0 0;

        &-title {
          display: block;
          font-size: 0.9em;
          font-weight: bold;
        }

        ul {
          margin: -3px 0 0 0;
          padding: 0 0 0 20px;
        }

        &-option {
          line-height: 30px;

          &-name {
            font-size: 1em;
            font-weight: bold;
          }

          &-price {
            font-size: 0.9em;
            color: #40464b;
          }

          &-description {
            font-size: 0.9em;

            &-title {
              font-weight: bold;
            }
          }

          &-flavors {
            font-size: 0.9em;

            &-title {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
