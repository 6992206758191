@import "../../variables.styles.scss";

.button {
  border: 0;
  background-color: $main-color-pink;
  color: white;
  transition: all 0.5s linear;

  &:hover {
    cursor: pointer;
    background-color: #a53247;
  }
}
