@import "../../variables.styles.scss";

.footer {
  min-width: $min-width;
  background-image: url("../../assets/components/footer/bread-sm.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: $breakpoint-md) {
    background-image: url("../../assets/components/footer/bread-xl.jpg");
  }

  &__image-layer {
    background-color: rgba(0, 0, 0, 0.88);
  }

  &__content {
    display: grid;

    @media (min-width: $breakpoint-lg) {
      grid-template-columns: 30% 25% 45%;
    }
  }

  &__coluna {
    @media (min-width: $breakpoint-md) {
      padding: 10px;
    }

    @media (min-width: $breakpoint-lg) {
      padding: 15px 30px;
    }

    &--logo {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__logo {
    padding-top: 30px;
    width: 210px;

    @media (min-width: $breakpoint-md) {
      width: 260px;
      height: 100%;
      background-repeat: no-repeat;
    }
  }

  &__title {
    font-family: "Merriweather";
    font-size: 1.1em;
    color: $main-color-white;
    padding: 0 0 6px 12px;
    border-bottom: $main-color-pink 1px solid;
  }

  &__list {
    padding: 0;
    color: $main-color-white;
    list-style: none;

    &-item {
      padding-bottom: 10px;
      display: block;

      &__links {
        color: $main-color-white;
        text-decoration: none;
        transition: all 0.5s;

        &::before {
          content: ">";
          margin-right: 5px;
          color: $main-color-pink;
        }

        &:hover {
          color: $main-color-green;
        }

        &--instagram::before {
          content: none;
        }

        &--facebook::before {
          content: none;
        }

        &--spotify::before {
          content: none;
        }
      }

      &--address::before {
        content: url("../../assets/components/footer/home.svg");
        margin: 0 5px 0 0;
        vertical-align: middle;
      }

      &--phone::before {
        content: url("../../assets/components/footer/phone.svg");
        margin: 0 5px 0 0;
        vertical-align: middle;
      }

      &--instagram::before {
        content: url("../../assets/components/footer/instagram.svg");
        margin: 0 5px 0 0;
        vertical-align: middle;
      }

      &--facebook::before {
        content: url("../../assets/components/footer/facebook.svg");
        margin: 0 5px 0 0;
        vertical-align: middle;
      }

      &--spotify::before {
        content: url("../../assets/components/footer/spotify.svg");
        margin: 0 5px 0 0;
        vertical-align: middle;
      }

      &--whatsapp::before {
        content: url("../../assets/components/footer/whatsapp.svg");
        margin: 0 5px 0 0;
        vertical-align: middle;
      }

      &--opening-hours::before {
        content: url("../../assets/components/footer/clock.svg");
        margin: 0 5px 0 0;
        vertical-align: middle;
      }
    }
  }
}
