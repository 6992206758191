@import "../../variables.styles.scss";

.map {
  min-width: $min-width;
  height: 0;
  padding-bottom: 60%;
  position: relative;
  overflow: hidden;

  @media (min-width: $breakpoint-sm) {
    padding-bottom: 40%;
  }

  @media (min-width: $breakpoint-md) {
    padding-bottom: 30%;
  }

  @media (min-width: $breakpoint-lg) {
    padding-bottom: 20%;
  }

  iframe {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    top: 0;
    left: 0;
  }
}
